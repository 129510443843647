export default function () {
    const qaArticles = [
        {
            Q: '「大自然界」とはどんな会ですか？',
            A: '「大自然界」は、大自然の大いなる生命のお力・お働きや大自然の真理法則を学び、より良く素晴らしい人生の生き方を行う為の会です。\n \
                大自然の大いなる生命『大親神大生命』は大いなる生命愛をもって、生きとし活ける全ての生物に生命をお与え下さり、生かして下さっておられます。\n \
                「大自然界」は人類全体が『大親神大生命』に対し奉り生きる喜びと感謝の念をもち、生命の家族としてお互いに助け合いプラスをし合って生きていく、そのような素晴らしい地球になっていく事を目標として活動しております。',
        },
        {
            Q: '「大自然界」ではどのような事をしているのですか？',
            A: '『大親神大生命』の貴き親理のお授けを頂き、大自然界の真理法則・摂理を学修させて頂いております。また、大自然の生命の大親『大親神大生命』に対し奉り、生命の感謝を捧げる活動（定期行事、日々のおつとめなど）を日々行わせて頂いております。',
            routeLabel: '「大自然界の活動」',
            routeUrl: { name: 'Activities' },
        },
        {
            Q: 'どのような教えですか？',
            A: '大自然の教えは、大自然の事実を事実として捉え認識し、より素晴らしく、より価値あるように生きる為の教えです。教えの内容は、宗教という枠組みを遥かに超えた、全生物に共通に通用する大自然の真理法則・摂理です。\n \
                大自然は真理法則のお力・お働きをもって、私たち全ての生物に生命をお与え下さり、生かして下さっております。したがって、私たちが素直に大自然の真理法則に沿って生きるよう努力すれば、人生はもっともっと素晴らしいものとなり、日々の実生活はより楽しい充実したものとなるのです。',
        },
        {
            Q: '大自然の真理法則はどのようにして分かったのですか？',
            A: '大自然の真理法則をもって全生物を生かして下さっている『大親神大生命』御自親より直接にお授け頂いたからです。『大親神大生命』御自親が貴き親理を今特別に人間の言葉で授けて下さっておられます。',
        },
        {
            Q: '大自然界の教えを学ぶと良い事がありますか？',
            A: '「『大親神大生命』、今日も一日、生存の味わいと喜びをお与え頂き有り難うございました！」と親感謝を行わせて頂きますと、心が安定し、満足感が湧いてきます。情緒が安定すると、生きる気力・活力の増大、不安・ストレスの解消、生きる喜び・幸福感・充実感の増大、マイナス思考からプラス思考への転換、人間関係の改善、運命・環境の好転につながります。\n \
                さらに、私たち一人一人の心がプラスに変わる事により、家庭や職場、地域・国、そして全世界へとプラスの影響が広がって、「大親神大生命」に対し奉り生命の感謝に溢れた、対立・争いのない、素晴らしい惑星地球に変わる事が出来るのです。',
        },
        {
            Q: '一般でも参加出来るセミナーなどはありますか？',
            A: '「大自然の教え・基本セミナー」をオンライン・オフラインで開講しております。どなたでも自由にお申し込み頂けます。',
            routeLabel: '「基本セミナー」お申し込みページへ',
            routeUrl: { name: 'Seminar' },
        },
        {
            Q: 'どこで勉強できますか？オンライン学修は出来ますか？',
            A: '本部である「大親所」、あるいは東京・九州をはじめ各地域の親支所、勉強グループなどで随時、勉強会を開催しております。また、「大自然の教え・基本セミナー」をオンライン・オフラインで開講しております。参加を希望される方は、下記リンクよりお申し込み下さい。',
            routeLabel: '「基本セミナー」お申し込みページへ',
            routeUrl: { name: 'Seminar' },
        },
        {
            Q: '入界をしなくても勉強できますか？',
            A: '大自然の教え・基本セミナーは、どなたでも自由に参加して頂く事が出来ます。\n \
                私たち生命ある者は皆、大自然により生命を与えて頂き、生かされ生きております。\n \
                「大自然界」の目的は、誰もが大自然の「生命の大親」＝『大親神大生命』の御存在と生命愛を実感し、大自然の真理法則に沿って、より良く素晴らしい人生を生きていけるようになることです。\n \
                大自然の真理法則を学ぶ事は、国籍・人種・民族・年齢・性別に関係無く、全ての人々にとって大切なことです。ぜひ一度ご参加下さい。',
            routeLabel: '「基本セミナー」お申し込みページへ',
            routeUrl: { name: 'Seminar' },
        },
        {
            Q: '日常の悩みなどのカウンセリングは受けられますか？',
            A: '勉強会（オフライン）あるいはオンラインで、個人的なカウンセリングや基礎講習を受ける事が出来ます。お問い合わせフォームよりお気軽にご相談下さい。',
            routeLabel: '「お問い合わせ」フォームへ',
            routeUrl: { name: 'Join' },
        },
        {
            Q: '他の宗教を信仰しているのですが、基本セミナーに参加することは出来ますか？',
            A: '大自然の真理法則を学修する場合は、宗教宗派は問いません。どなたでも自由に参加して頂く事が出来ます。\n \
                大自然の大いなる生命である『大親神大生命』のお力・お働きは、生命ある全生物に働いております。したがって本質的には、生命あるものは既に大自然の『大親神大生命』の「生命の家族」であり、大自然界の一員なのです。\n \
                大自然の教え・基本セミナーは、宗教宗派・人種・民族・年齢・性別に関係無く、全ての人々にとって役に立つ大切なセミナーです。どうぞ気兼ねなくご参加下さい。',
            routeLabel: '「基本セミナー」お申し込みページへ',
            routeUrl: { name: 'Seminar' },
        },
        {
            Q: '何か強制される事はありますか？',
            A: '強制や束縛は一切ありません。『大親神大生命』より次の様にお授け頂いております。\n \
                「大自然界では、界員も募集しておりませんし、寄付などの強要も一切ございません。ただ地球を良くする為に、皆さんに素晴らしく生きてもらいたいのです。これは、皆さん自身の意識・気持ちの問題です。新しい意識や気持ちは、自らが積極的に創り上げていく必要があります。強制されるものではありません。いかに素晴らしく生きるか、どう価値あるように生きるか、どれだけ良く生きるかは、自己責任です。それをするかしないかは、皆さん方の自由意志です。しかし、良い事はした方が良いでしょう。早く素晴らしくなって頂きたい、それだけを目的に、親理のお授けを行っているのです。」',
        },
        {
            Q: '大自然の教えを勉強するのに、お金はかかりますか？',
            A: '本科受講料、カウンセリング（個人相談）料、教材・テキスト代などの費用は一切無料ではありますが、その代わり「おつなぎ徳積み」ということを行わせて頂いております。',
        },
        {
            Q: '「おつなぎ徳積み」とは何ですか？',
            A: '「おつなぎ徳積み」とは、大自然に対する生存の喜びと感謝の念をもって、世のため人のために、自らの能動的意志で行う行為のことです。\n \
                人のため世のために行なったプラスの種が、「循還の理法」によって結局、自分の運命や環境に「徳」となって大きく巡ってくるのです。「おつなぎ徳積みの理」は、他人にも自分にもプラスに循還してゆく大自然の真理法則の一つです。\n \
                大切なことは、自らの真心で自発的に行うことです。それ故に、人間のお金をもって「おつなぎ徳積み」をさせて頂く場合、金額も各自が自らの自由意志で定め、無記名で「おつなぎの箱」に入れ、プラスの徳積みの種まきを行わせて頂きます。',
            routeLabel: '「おつなぎ徳積みの理」',
            routeUrl: { name: 'Donate' },
        },
        {
            Q: '「おつなぎ徳積みの理」で捧げたお金は、何に使われているのですか？',
            A: '「おつなぎ徳積みの理」のお金は、「生命あるものをより良く活かす」という『大親神大生命』の大自然の理に沿って、大自然の真理法則の啓蒙運動の為と、世界中の生命の救済の為に使われます。\n \
                「おつなぎ徳積みの理」を行わせて頂く場合は、自分の現世御利益を願うのではなく、他の為・地球全体の為に、真心を込めて念じさせて頂きます。\n \
                『大親神大生命』に「おつなぎ徳積みの理」としてお受取り頂けると、他の為に役立つ行いをした＝人助けのプラスの種を蒔いた事になります。人助けのプラスの種が「循環の理法」により、結局、自分にプラスの収穫として多く実ってくるのです。',
            routeLabel: '「おつなぎ徳積みの理」',
            routeUrl: { name: 'Donate' },
        },
        {
            Q: '大自然界は宗教ですか？',
            A: '「大自然界」は形式上「宗教法人」を取得しておりますが、教えの内容は、宗教という枠組みを遙かに超えた、全生物に永遠・普遍共通に通用する大自然の真理・摂理です。\n \
                全生物は、大自然界の大いなる生命のお力・お働きによって活かされ生きております。生命に宗教宗派はありません。犬や猫、草木は何教でしょうか。宗教宗派は人間が作り出したものですが、人類の発生以前から、この地球上で45億年にわたる生命現象が続いて来ております。\n \
                しかし、大自然の生命のお力・お働きが無ければ、この地球上に生命現象は有り得ません。大自然の大いなる生命のお力・お働きは、宗教宗派・国家・人種・民族・老若男女の差別はなく、さらには人間・動物・植物の差別もありません。生命ある全生物に働いているものです。\n \
                今まで地球上では、宗教宗派で異なる神を崇拝し、そして神や教義の違いが人間同士の差別や対立・争いを生んでまいりました。宗教宗派を超越した永遠・普遍共通の御存在＝大自然の大いなる生命の御存在を悟らせて頂く事が出来れば、私たちはみな生命の家族・生命の兄弟としてプラスをし合って、もっと仲良く豊かに平和に暮らす事が出来るはずです。\n \
                人類がみな、全生物を生かしていてくださる大自然の大いなる生命「大親神大生命」に対し奉り、感謝の念をもち、お互いに助け合い支え合い生きていく―地球がそのような素晴らしい惑星になることを、「大自然界」は目標とさせて頂いております。',
        },
        {
            Q: '教祖様は、どなたですか？',
            A: '大自然界には、教祖はおりません。大自然界の教え（親理）は、大自然界を創造され営んで下さっている『大親神大生命』御自親がお授け下さっておられます。人間が考え出したり生み出した教えではありません。\n \
                『大親神大生命』は、大自然界のあらゆる事実（現象・事柄）をもって、大自然の真理法則をお示し下さっておられます。しかし、私たちが分からないので、人間に判るよう、特別に人間の言葉を使用されてお授けして下さっているのです。',
        },
        {
            Q: 'どうして本当の事だと分かるのですか？',
            A: '大自然界でお授け頂いている教え（大自然の真理法則）は、大自然の全て、大自然の真実そのものだからです。\n \
                例えば、全てを循環させて下さっているお力・お働きを「循環・還元の理法」といいます。「循環・還元の理法」は、マクロの世界である大宇宙にも、さらにミクロの世界である原子・分子の世界にも働いて、全てを循環させています。体内の心臓の鼓動、血液の循環、新陳代謝、睡眠と覚醒、春夏秋冬の移り変わり、海流の流れなども全て循環です。麦の種を蒔いて麦が実り、またその生えた麦が種となって実を実らせる現象も循環です。\n \
                このように大自然は、大自然の全ての物事が循環・還元している事実を持って「循環・還元の理法」が働いている事実を証明しています。これを「自明の理」と言います。\n \
                このように大自然界の教えは、大自然の事実・真実の教えであり、大自然自体が大自然の事実の全てをもって証明して下さっている、「自明の理」の教えなのです。',
        },
        {
            Q: '他の宗教や人生セミナーで同じことを聞いたことがあるのですが…',
            A: '大自然の真理法則は、大自然の全てに通用する永遠・普遍共通なる大自然の掟です。大自然の真理法則は誰にでも働いているものですので、人生や運命が良くなる方法というのは、同じ内容になるのがむしろ当然です。\n \
                「幸せの種を蒔く」「常に笑顔」「感謝をする」「プラス思考」「愚痴や不足不満、人の悪口を言わない」「人の役に立つ」･･･。大自然の真理法則に適ったプラスの行いをすれば、人生や運命は必ず良くなります。\n \
                大自然界では、大自然の真理法則に沿って素晴らしく生きる事は勿論ですが、何よりも先ず、その大自然の真理法則によって全てをお創り下さり、私たちを生かして下さっている「生命の大親」の御存在と生命愛を悟り、生命の感謝を行わせて頂く事が、一番大切な事だと教えて頂いております。',
        },
    ]

    return { qaArticles }
}
