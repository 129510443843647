<template>
    <div id="QA">
        <section id="qa-banner" class="banner-section-style">
            <div class="mobile-title hide">
                <h1 class="f20-mb">Q&A</h1>
            </div>
            <div
                class="banner"
                :style="
                    addBackgroundImage(
                        require('@/assets/background/background-1.jpg'),
                        require('@/assets/background/background-1-mb.jpg')
                    )
                "
            ></div>
        </section>
        <section id="qa-title" class="title-section-style hide-mb">
            <h2 class="title f29">Q&A</h2>
        </section>
        <section id="qa-main">
            <div class="wrap w80">
                <aside class="sticky-container-style hide-mb">
                    <h3 class="head f18">Q. 質問リスト</h3>
                    <ul class="body f12">
                        <li
                            v-for="(item, index) in qaArticles"
                            :key="'qa-menu-' + index"
                            class="menu-item"
                            @click="scrollToElement('#qa-item' + index)"
                        >
                            ・{{ item.Q }}
                        </li>
                    </ul>
                </aside>
                <main>
                    <article
                        v-for="(item, index) in qaArticles"
                        :key="'qa-item' + index"
                        :id="'qa-item' + index"
                        :name="'qa-item' + index"
                    >
                        <div class="head">
                            <span class="icon f29 f22-mb">Q.</span>
                            <h2 class="f18 f14-mb">{{ item.Q }}</h2>
                        </div>
                        <div class="body">
                            <span class="icon f29 f22-mb">A.</span>
                            <div>
                                <p class="f14 f12-mb" v-html="wrap(item.A)"></p>
                                <router-link
                                    v-if="item.routeUrl"
                                    :to="item.routeUrl"
                                    class="f14 f12-mb"
                                    >{{ item.routeLabel }}
                                    <i class="icon-page-link f12 f12-mb"></i
                                ></router-link>
                            </div>
                        </div>
                    </article>
                </main>
            </div>
        </section>
    </div>
</template>
<script>
import { addBackgroundImage, wrap, scrollToElement } from '@/utils/common.js'
import getQaData from '@/data/getQaData.js'
export default {
    name: 'QA',
    setup() {
        const { qaArticles } = getQaData()
        return {
            addBackgroundImage,
            wrap,
            scrollToElement,
            qaArticles,
        }
    },
}
</script>
<style lang="scss" scoped>
#qa-main {
    .wrap {
        display: flex;
    }
    aside {
        flex: 0 1 400px;
        margin-right: 50px;
        .menu-item {
            padding-left: 1em;
            text-indent: -1em;
        }
    }
    main {
        flex: 1 1 500px;
    }
    article {
        margin-bottom: 25px;
        .head,
        .body {
            position: relative;
            display: flex;
            .icon {
                position: absolute;
            }
        }
        .head {
            background-color: rgba($purpleB, 0.3);
            .icon {
                color: #8b82bc;
            }
        }
        .body {
            a {
                display: block;
                text-align: right;
                margin: 1.5em 0 1em;
                color: $black;
                @include underlineStyle($majorPurple, bold);
                transition: 0.3s;
                &:hover {
                    color: $gray6;
                }
            }
            .icon {
                color: $gray6;
            }
        }
    }

    @media screen and (min-width: 768px) {
        background-color: $minorGold;
        padding: 100px 0;
        article {
            box-shadow: $shadow2;
            background: white;
        }
        main {
            .head,
            .body {
                padding: 25px 45px 25px 75px;
                .icon {
                    left: 30px;
                    top: 15px;
                }
            }
            .head {
                h2 {
                    line-height: 1.5em;
                }
            }
        }
    }
    @media screen and(max-width:767px) {
        padding: 50px 0;
        main {
            .head,
            .body {
                padding: 20px 20px 20px 50px;
                .icon {
                    left: 20px;
                    top: 12px;
                }
            }
        }
    }
}
</style>
